import { ICategory, ITopLevelCategories } from './api.interfaces';

export const CAT_CATEGORY = 'category';
export const CAT_BRAND = 'brand';
export const CAT_REGION = 'region';
export const CAT_COMMERCE = 'commerce';
export const CAT_FLASHDEAL = 'flashdeal';

export const TOP_LEVEL_CATEGORIES_NAMES = [
  CAT_CATEGORY,
  CAT_BRAND,
  CAT_REGION,
  CAT_COMMERCE,
  CAT_FLASHDEAL,
] as const;

export const DEFAULT_EMPTY_TOP_LEVEL_CATEGORIES: ITopLevelCategories = {
  [CAT_CATEGORY]: { name: CAT_CATEGORY, subCategories: [] as ICategory[] },
  [CAT_BRAND]: { name: CAT_BRAND, subCategories: [] as ICategory[] },
  [CAT_REGION]: { name: CAT_REGION, subCategories: [] as ICategory[] },
  [CAT_COMMERCE]: { name: CAT_COMMERCE, subCategories: [] as ICategory[] },
  [CAT_FLASHDEAL]: { name: CAT_FLASHDEAL, subCategories: [] as ICategory[] },
};
