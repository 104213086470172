export class QueryParamsBuilder {
  static serializeQueryParams(
    params: Record<string, any>,
  ): Record<string, string> {
    if (!params) return {};

    const queryParams: Record<string, string> = {};

    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          if (value.length === 0) return;
          const serializedKey = key.endsWith('[]') ? key : `${key}[]`;
          queryParams[serializedKey] = value.join(',');
        } else queryParams[key] = `${value}`;
      }
    });

    return queryParams;
  }

  static deserializeQueryParams<
    T extends Record<string, string | string[]> = Record<
      string,
      string | string[]
    >,
  >(params: Record<string, string>): T {
    if (!params) return {} as T;

    const queryParams: Record<string, string | string[]> = {} as T;

    Object.entries(params).forEach(([key, value]) => {
      if (key.includes('[]')) {
        queryParams[key.replace('[]', '')] = value.split(',');
      } else queryParams[key] = value;
    });

    return queryParams as T;
  }

  static serializeApiQueryParams(params: Record<string, any>): string {
    if (!params) return '';

    const queryParams: string[] = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          if (value.length === 0) return;

          queryParams.push(
            `${this.camelCaseToSnakeCase(key)}[]=${value.join(',')}`,
          );
        } else queryParams.push(`${this.camelCaseToSnakeCase(key)}=${value}`);
      }
    });
    return queryParams.join('&');
  }

  static deserializeApiQueryParams(paramsStr: string): Record<string, any> {
    if (!paramsStr) return {};

    const queryParams: Record<string, any> = {};
    paramsStr.split('&').forEach((queryParam) => {
      const [key, value] = queryParam.split('=');
      if (value.includes(',')) {
        queryParams[key.replace('[]', '')] = value.split(',');
      } else queryParams[key] = value;
    });
    return queryParams;
  }

  static camelCaseToSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }
}
