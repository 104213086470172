import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { QueryParamsBuilder } from './query-params-builder';
import {
  ICategoriesResponse,
  IProduct,
  IProductResponse,
  IProductsResponse,
  ITopLevelCategories,
  ITopLevelCategoriesIdsNameMap,
} from './api.interfaces';
import { ICategory, IProductTypesResponse } from './api.interfaces';
import { TOP_LEVEL_CATEGORIES_NAMES } from './categories.constants';

@Injectable({ providedIn: 'root' })
export class ApiService {
  topLevelCategoriesIdsNameMap: ITopLevelCategoriesIdsNameMap | undefined =
    undefined;

  httpClient = inject(HttpClient);

  baseUrl = environment.baseApiUrl;

  constructor() {}

  login(email: string, password: string) {
    return this.httpClient.post(
      `${this.baseUrl}/auth`,
      { email, password },
      { withCredentials: true },
    );
  }

  logout() {
    return this.httpClient.delete<string>(`${this.baseUrl}/auth`, {
      withCredentials: true,
    });
  }

  resetPassword(email: string) {
    return this.httpClient.post(`${this.baseUrl}/password-reset`, {
      email,
    });
  }

  getCategoriesWithAllProducts(withProducts = false) {
    return this.httpClient
      .get<ICategoriesResponse>(
        `${this.baseUrl}/product-categories?${
          withProducts ? 'expand=products' : ''
        }`,
        {
          withCredentials: true,
        },
      )
      .pipe(
        switchMap((response) =>
          this.buildProductsRequestUrl(response.product_categories).pipe(
            map((products) => {
              // cache top level categories ids
              if (this.topLevelCategoriesIdsNameMap === undefined) {
                this.topLevelCategoriesIdsNameMap =
                  response.product_categories.reduce((acc, category) => {
                    const key =
                      category.name.toLocaleLowerCase() as keyof ITopLevelCategories;
                    if (
                      key &&
                      TOP_LEVEL_CATEGORIES_NAMES.some((x) => x === key)
                    ) {
                      acc[category.id] = key;
                    }
                    return acc;
                  }, {} as ITopLevelCategoriesIdsNameMap);
              }

              const productCategories = response.product_categories.map(
                (category) =>
                  ({
                    ...category,
                    products: products.products.filter(
                      (product) =>
                        product.categories?.findIndex(
                          (cat) => cat.id === category.id,
                        ) !== -1,
                    ),
                  }) as ICategory,
              );

              // group categories by top level category
              const categories = productCategories.reduce((acc, category) => {
                const key =
                  category.name.toLocaleLowerCase() as keyof ITopLevelCategories;
                if (key && TOP_LEVEL_CATEGORIES_NAMES.some((x) => x === key)) {
                  acc[key] ??= { name: key, subCategories: [] as ICategory[] };
                  Object.assign(acc[key], {}, category);
                }

                if (
                  category.parent_category_id &&
                  this.topLevelCategoriesIdsNameMap
                ) {
                  const key =
                    this.topLevelCategoriesIdsNameMap[
                      category.parent_category_id
                    ];
                  acc[key] ??= { name: key, subCategories: [] as ICategory[] };
                  acc[key].subCategories ??= [];
                  acc[key].subCategories.push(category);
                }

                return acc;
              }, {} as ITopLevelCategories);

              return categories;
            }),
          ),
        ),
      );
  }

  private buildProductsRequestUrl(categories: ICategory[]) {
    const categoriesIds = categories.map((x) => x.id);
    const queryParams = categoriesIds
      .map((id, i) => `category_id[${i}]=${id}`)
      .join('&');
    return this.httpClient.get<IProductsResponse>(
      `${this.baseUrl}/products?expand=categories,variants,variants.prices,type,images&${queryParams}`,
      { withCredentials: true },
    );
  }

  getProducts(options: {
    categoryId?: string[] | null;
    typeId?: string[] | null;
  }) {
    const queryParamsBuilder =
      QueryParamsBuilder.serializeApiQueryParams(options);

    return this.httpClient
      .get<IProductsResponse>(
        `${this.baseUrl}/products?expand=categories,variants,variants.prices,type,images&${queryParamsBuilder}`,
        {
          withCredentials: true,
        },
      )
      .pipe(map((x) => x.products));
  }

  searchProducts(query: string) {
    return this.httpClient
      .post<{ hits: IProduct[] }>(
        `${this.baseUrl}/products/search`,
        { q: query },
        {
          withCredentials: true,
        },
      )
      .pipe(map((x) => x.hits));
  }

  getProductsTypes() {
    return this.httpClient
      .get<IProductTypesResponse>(`${this.baseUrl}/product-types`, {
        withCredentials: true,
      })
      .pipe(map((x) => x.product_types));
  }

  getProduct(id: string) {
    return this.httpClient
      .get<IProductResponse>(
        `${this.baseUrl}/products/${id}?expand=categories,variants,variants.prices,type,images`,
        {
          withCredentials: true,
        },
      )
      .pipe(map((x) => x.product));
  }
}
